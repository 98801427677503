import React, { useEffect, useState, useContext } from "react";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import "./feedbackv2.scss";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { ClientFeedbackDetail, ResetFeedBack } from "../../../../actions/feedback/customerFeedBackAction";

const FeedbackForm = () => {
  const { theme } = useContext(ThemeContext);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';
  const loginCheck = localStorage.getItem('isLoggedIn');
  const navigate = useNavigate();


  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;


  useEffect(() => {
    if (loginCheck) {
      const clientEmail = localStorage.getItem('email');
      setEmail(clientEmail);
    }
  }, [loginCheck]);

  const feedbackStatus = useSelector(state => state.ClientFeedbackReducer);
  useEffect(() => {
    if (feedbackStatus?.response) {
      setButtonLoading(false);
      setMessage('');
      toast.success("FeedBack added successfully!");
      dispatch(ResetFeedBack());
    }
  }, [feedbackStatus, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userAgents = navigator.userAgent;
    const pathName = location.pathname;

    const data = {
      path: pathName,
      userAgent: userAgents,
      email: email,
      comment: message
    };

    dispatch(ClientFeedbackDetail(data));
    setButtonLoading(true);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
{width > breakpoint ? (
      <div className="feedback-form-container">
        <h5 className='arrow-text'>Send Feedback</h5>
        <p>Tell Us What You Love About The App, Or What We Could Be Doing Better</p>
        {/* {loginCheck && <p>Logged in as: {email}</p>} */}
        <form onSubmit={handleSubmit}>
          <div className="form-groupfeedback">
            <textarea
              rows="3"
              placeholder="Enter Feedback"
              className="form-controlfeedback"
              value={message}
              onChange={handleMessageChange}
              autoFocus
            ></textarea>
          </div>
          <div className="help-card">
            <div>
              <img src="https://fusionbucket.co.uk/My-account/feedbackstarimage.png" alt="star-icon" className='feedback-icon' />
            </div>
            <div className="text-container">
              <h3>Need Help With Your Order?</h3>
              <p>Get Instant Help From Our Customer Support Team</p>
            </div>
          </div>
          <div className="submit-feedback">
          <button type="submit" className="submitbuttonnew">
            {buttonLoading ? (
              <Spinner animation="border" size="sm" />
            ) : ("Send Feedback")}
          </button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </form>
      </div>
):(
<div className="feedback-form-container">
        
        
        <h5 className='arrow-text'><MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} /> Send Feedback</h5>
        <p>Tell Us What You Love About The App, Or What We Could Be Doing Better</p>
        <form onSubmit={handleSubmit}>
          <div className="form-groupfeedback">
            <textarea
              rows="3"
              placeholder="Enter Feedback"
              className="form-controlfeedback"
              value={message}
              onChange={handleMessageChange}
              autoFocus
            ></textarea>
          </div>
          <div className="help-card">
            <div>
              <img src="https://fusionbucket.co.uk/My-account/feedbackstarimage.png" alt="star-icon" className='feedback-icon' />
            </div>
            <div className="text-container">
              <h3>Need Help With Your Order?</h3>
              <p>Get Instant Help From Our Customer Support Team</p>
            </div>
          </div>
          <div className="submit-feedback">
          <button type="submit" className="submitbuttonnew">
            {buttonLoading ? (
              <Spinner animation="border" size="sm" />
            ) : ("Send Feedback")}
          </button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </form>
      </div>
)}
    </>
  );
};

export default FeedbackForm;

