// src/reducers/myaccount/address/addAddressReducer.js
import {
    GETFAVOURITE_SUCCESS,
    GETFAVOURITE_FAILURE,
  } from "../../../actions/types";
  
  const initialState = {
    favourite: null,
    error: null,
  };
  
  export const favouriteReducer = (state = initialState, action) => {
    switch (action.type) {
      case GETFAVOURITE_SUCCESS:
        return {
          ...state,
          favourite: action.payload,
          error: null,
        };
      case GETFAVOURITE_FAILURE:
        return {
          ...state,
          favourite: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  