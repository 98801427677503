import React, { useState,useContext,useEffect } from 'react';
import { Container, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import "./paymentmyaccount.scss";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import { useNavigate ,useLocation} from 'react-router-dom';
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import "../addressbook/css/addressmodal.scss";




function Paymentsettings() {
  const { theme } = useContext(ThemeContext);
  const breakpoint = 767;
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';


  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const navigateto = useNavigate();
  if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/addressaccount");
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section>
    {width > breakpoint ? (  <>
      <div className='payment-module'>
        <Container>
          <h5 className='arrow-text'>
            {/* <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')}/> */}
            {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-payments' onClick={handlemyaccountclick}/> */}
            Payment Settings
          </h5>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Group 1171277305.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Fusion Money </span><br />
                  </div>
                  <img src="https://fusionbucket.co.uk/My-account/downpayement arrow.png" alt="arrowdown" />
                </div>
              </div>
              <h6 className='cards-texts'>C A R D S</h6>
              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Group 1171277306.png" alt="creditcard" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add Credit or Debit Card </span>
                  </div>
                  <p className='addtext-payment' onClick={handleShow}>ADD</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/pluxee.png" alt="Pluxee-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add Pluxee </span>
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              {/* <h6 className='cards-texts'>WALLETS</h6> */}

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Amazonpay.png" alt="Amazonpay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Amazon Pay Balance</span>
                    <p className='link-testpayment'>Link Your Amazon Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/mobwkwiwki.png" alt="Mobiwiki" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Mobiwkwiki </span>
                    <p className='link-testpayment'>Link Your Mobiwkwiki Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/paytmpay.png" alt="paytm" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Paytm</span><br />
                    <p className='link-testpayment'>Link Your paytm Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
              </div>
            </Col>
            
          </Row>
          <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              {/* <h6 className='cards-texts' > <img src="https://fusionbucket.co.uk/My-account/upipayment.png" alt="UPI-payments" />PAY  BY  ANY  UPI  APP</h6> */}
              <Modal show={show} onHide={handleClose} className='modalpayment-popup'
              id="addr-book-modalsetting"
              >
                <Modal.Body className={theme === "dark" ? "modal-paymentsdrk" : "modal-payments"}>
                  <form>
                    <h2>Add Card <img src="https://fusionbucket.co.uk/My-account/closebutton.png" alt="close-tn" className='close-btnmodal' onClick={handleClose} /></h2>
                    <div className="form-group">
                      <input className="form-control" id="cardNumber" placeholder="Name On Card " />
                    </div>
                    <div className="form-group">
                      <input className="form-control" id="expiryDate" placeholder="Card Number" />
                    </div>
                    <div className="form-group">

                      <input className="form-control" id="cvv" placeholder="Expiry Date (MM/YY)" />
                    </div>
                    <Button variant="primary" className='addcard-btn' onClick={handleClose}>
                      Add & Secure Card
                    </Button>
                  </form>
                </Modal.Body>
              </Modal>

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/gpayicon.png" alt="google-pay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Google pay UPI  </span>
                  </div>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/UPI icon.png" alt="Upi-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add New UPI ID  </span>
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              {/* <h6 className='cards-texts'>PAY LATER </h6> */}

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/simpl.png" alt="simpl" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Simpl  </span><br />
                    <p className='link-testpayment'>Link Your simpl Account </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Lazypay.png" alt="lazypay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Lazypay</span><br />
                    <p className='link-testpayment'>Link Your Lazypay Account </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              {/* <h6 className='cards-texts'>NET BANKING </h6> */}

              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/netbanking.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Net Banking </span><br />
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              {/* <h6 className='cards-texts'>SETTINGS </h6> */}
              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/onlineorder.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Pay On Delivery </span><br />
                    {/* <p className='link-testpayment'>If Online Payments Fails</p><br /> */}
                  </div>
                  <p className='addtext-payment'>ENABLE</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>):(  <>
      <div className='payment-module'>
        <Container>
          <h5 className='arrow-text'>
            <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
            {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-payments' onClick={handlemyaccountclick}/> */}
            Payment Settings
          </h5>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Group 1171277305.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Fusion Money </span><br />
                  </div>
                  <img src="https://fusionbucket.co.uk/My-account/downpayement arrow.png" alt="arrowdown" />
                </div>
              </div>
              <h6 className='cards-texts'>C A R D S</h6>
              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Group 1171277306.png" alt="creditcard" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add Credit or Debit Card </span>
                  </div>
                  <p className='addtext-payment' onClick={handleShow}>ADD</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/pluxee.png" alt="Pluxee-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add Pluxee </span>
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <h6 className='cards-texts' > <img src="https://fusionbucket.co.uk/My-account/upipayment.png" alt="UPI-payments" />PAY  BY  ANY  UPI  APP</h6>
              <Modal show={show} onHide={handleClose} dialogClassName="bottom-modal">
                <Modal.Body className={theme === "dark" ? "modal-paymentsdrk" : "modal-payments"}>
                  <form>
                    <h2>Add Card <img src="https://fusionbucket.co.uk/My-account/closebutton.png" alt="close-tn" className='close-btnmodal' onClick={handleClose} /></h2>
                    <div className="form-group">
                      <input className="form-control" id="cardNumber" placeholder="Name On Card " />
                    </div>
                    <div className="form-group">
                      <input className="form-control" id="expiryDate" placeholder="Card Number" />
                    </div>
                    <div className="form-group">

                      <input className="form-control" id="cvv" placeholder="Expiry Date (MM/YY)" />
                    </div>
                    <Button variant="primary" className='addcard-btn' onClick={handleClose}>
                      Add & Secure Card
                    </Button>
                  </form>
                </Modal.Body>
              </Modal>

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/gpayicon.png" alt="google-pay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Google pay UPI  </span>
                  </div>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/UPI icon.png" alt="Upi-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Add New UPI ID  </span>
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <h6 className='cards-texts'>WALLETS</h6>

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Amazonpay.png" alt="Amazonpay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Amazon Pay Balance</span>
                    <p className='link-testpayment'>Link Your Amazon Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/mobwkwiwki.png" alt="Mobiwiki" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Mobiwkwiki </span>
                    <p className='link-testpayment'>Link Your Mobiwkwiki Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/paytmpay.png" alt="paytm" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Paytm</span><br />
                    <p className='link-testpayment'>Link Your paytm Wallet </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <h6 className='cards-texts'>PAY LATER </h6>

              <div className='payments-orderpay'>
                <div className="food-items">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/simpl.png" alt="simpl" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Simpl  </span><br />
                    <p className='link-testpayment'>Link Your simpl Account </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
                <div className="food-itemslast">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/Lazypay.png" alt="lazypay" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Lazypay</span><br />
                    <p className='link-testpayment'>Link Your Lazypay Account </p>
                  </div>
                  <p className='addtext-payment'>LINK</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <h6 className='cards-texts'>NET BANKING </h6>

              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/netbanking.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Net Banking </span><br />
                  </div>
                  <p className='addtext-payment'>ADD</p>
                </div>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
              <h6 className='cards-texts'>SETTINGS </h6>
              <div className="payments-orderpay">
                <div className="food-payments">
                  <div className="img-foodorder">
                    <img src="https://fusionbucket.co.uk/My-account/onlineorder.png" alt="group-icon" />
                  </div>
                  <div className="text-content">
                    <span className="payment-tittle">Pay On Delivery </span><br />
                    {/* <p className='link-testpayment'>If Online Payments Fails</p><br /> */}
                  </div>
                  <p className='addtext-payment'>ENABLE</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>)}
    </section>
  );
}

export default Paymentsettings;



// import React, { useState, useContext } from 'react';
// import { Container, Button, Modal } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
// import "./paymentmyaccount.scss";

// const paymentMethods = [
//   { src: "https://fusionbucket.co.uk/My-account/Group 1171277306.png", title: "Add Credit or Debit Card", action: "ADD" },
//   { src: "https://fusionbucket.co.uk/My-account/pluxee.png", title: "Add Pluxee", action: "ADD" },
// ];

// const upiMethods = [
//   { src: "https://fusionbucket.co.uk/My-account/gpayicon.png", title: "Google pay UPI" },
//   { src: "https://fusionbucket.co.uk/My-account/UPI icon.png", title: "Add New UPI ID", action: "ADD" },
// ];

// const walletMethods = [
//   { src: "https://fusionbucket.co.uk/My-account/Amazonpay.png", title: "Amazon Pay Balance", link: "Link Your Amazon Wallet", action: "LINK" },
//   { src: "https://fusionbucket.co.uk/My-account/mobwkwiwki.png", title: "Mobiwkwiki", link: "Link Your Mobiwkwiki Wallet", action: "LINK" },
//   { src: "https://fusionbucket.co.uk/My-account/paytmpay.png", title: "Paytm", link: "Link Your paytm Wallet", action: "LINK" },
// ];

// const payLaterMethods = [
//   { src: "https://fusionbucket.co.uk/My-account/simpl.png", title: "Simpl", link: "Link Your simpl Account", action: "LINK" },
//   { src: "https://fusionbucket.co.uk/My-account/Lazypay.png", title: "Lazypay", link: "Link Your Lazypay Account", action: "LINK" },
// ];

// const netBankingMethods = [
//   { src: "https://fusionbucket.co.uk/My-account/netbanking.png", title: "Net Banking", action: "ADD" },
// ];

// const PaymentSettings = () => {
//   const { theme } = useContext(ThemeContext);
//   const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
//   const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';

//   const [show, setShow] = useState(false);
//   const navigate = useNavigate();

//   const handleMyAccountClick = () => {
//     navigate('/myaccount');
//   };

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const renderPaymentMethod = (method, index) => (
//     <div key={index} className="food-items">
//       <div className="img-foodorder">
//         <img src={method.src} alt={method.title} />
//       </div>
//       <div className="text-content">
//         <span className="payment-tittle">{method.title}</span>
//         {method.link && <p className='link-testpayment'>{method.link}</p>}
//       </div>
//       {method.action && <p className='addtext-payment' onClick={handleShow}>{method.action}</p>}
//     </div>
//   );

//   return (
//     <>
//       <div className='payment-module'>
//         <Container>
//           <h5 className='arrow-text'>
//             <img
//               src={theme === "dark" ? Imagetopdark : Imagetop}
//               className='arrow-payments'
//               onClick={handleMyAccountClick}
//             />
//             Payment Settings
//           </h5>
//           <div className="payments-orderpay">
//             <div className="food-payments">
//               <div className="img-foodorder">
//                 <img src="https://fusionbucket.co.uk/My-account/Group 1171277305.png" alt="group-icon" />
//               </div>
//               <div className="text-content">
//                 <span className="payment-tittle">Fusion Money</span><br />
//               </div>
//               <img src="https://fusionbucket.co.uk/My-account/downpayement arrow.png" alt="arrowdown" />
//             </div>
//           </div>
//           <h6 className='cards-texts'>C A R D S</h6>
//           <div className='payments-order'>
//             {paymentMethods.map(renderPaymentMethod)}
//           </div>
//           <h6 className='cards-texts'>
//             <img src="https://fusionbucket.co.uk/My-account/upipayment.png" alt="UPI-payments" />PAY BY ANY UPI APP
//           </h6>
//           <div className='payments-order'>
//             {upiMethods.map(renderPaymentMethod)}
//           </div>
//           <Modal show={show} onHide={handleClose} dialogClassName="bottom-modal">
//             <Modal.Body className={theme === "dark" ? "modal-paymentsdrk" : "modal-payments"}>
//               <form>
//                 <h2>Add Card <img src="https://fusionbucket.co.uk/My-account/closebutton.png" alt="close-tn" className='close-btnmodal' onClick={handleClose} /></h2>
//                 <div className="form-group">
//                   <input className="form-control" id="cardNumber" placeholder="Name On Card" />
//                 </div>
//                 <div className="form-group">
//                   <input className="form-control" id="expiryDate" placeholder="Card Number" />
//                 </div>
//                 <div className="form-group">
//                   <input className="form-control" id="cvv" placeholder="Expiry Date (MM/YY)" />
//                 </div>
//                 <Button variant="primary" className='addcard-btn' onClick={handleClose}>
//                   Add & Secure Card
//                 </Button>
//               </form>
//             </Modal.Body>
//           </Modal>
//           <h6 className='cards-texts'>WALLETS</h6>
//           <div className='payments-order'>
//             {walletMethods.map(renderPaymentMethod)}
//           </div>
//           <h6 className='cards-texts'>PAY LATER</h6>
//           <div className='payments-order'>
//             {payLaterMethods.map(renderPaymentMethod)}
//           </div>
//           <h6 className='cards-texts'>NET BANKING</h6>
//           <div className="payments-order">
//             {netBankingMethods.map(renderPaymentMethod)}
//           </div>
//           <h6 className='cards-texts'>SETTINGS</h6>
//           <div className="payments-order">
//             <div className="food-payments">
//               <div className="img-foodorder">
//                 <img src="https://fusionbucket.co.uk/My-account/onlineorder.png" alt="group-icon" />
//               </div>
//               <div className="text-content">
//                 <span className="payment-tittle">Pay On Delivery</span><br />
//               </div>
//               <p className='addtext-payment'>ENABLE</p>
//             </div>
//           </div>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default PaymentSettings;

