// src/actions/myaccount/orderstatus/orderTrackingActions.jsx
import { getFavoriteApi } from "../../../api/myaccount/favourite/favouriteApi";
import {
    GETFAVOURITE_SUCCESS,
    GETFAVOURITE_FAILURE,
} from "../../types";

export const getFavoriteApiSuccess = (favourite) => ({
  type: GETFAVOURITE_SUCCESS,
  payload: favourite,
});

export const getFavoriteApiFailure = (error) => ({
  type: GETFAVOURITE_FAILURE,
  payload: error,
});

export const getFavouriteList = (postData) => {
  return (dispatch) => {
    getFavoriteApi(postData)
      .then((response) => {
        dispatch(getFavoriteApiSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(getFavoriteApiFailure(error.message));
        return error.message;
      });
  };
};
