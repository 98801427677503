import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FaHeart, FaStar } from 'react-icons/fa';
import './favouriteorder.scss';
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import {
    MdOutlineArrowBackIos,
} from "react-icons/md";
import { getFavouriteList } from '../../../../actions/myaccount/favourite/favouriteAction';
import EmptyImg from './img/download.png';

const IMAGE_URL = "https://fusionbucket.co.uk/My-account/Screenshot 2024-10-24 at 6.57.50 PM 1.png";

const RestaurantCard = ({ restaurant, getFavouriteList, favourite, key }) => {
    const navigate =useNavigate();
    const imgDef = restaurant.bg_image ? restaurant.bg_image : EmptyImg;
    const handleError = (e) => {
        e.target.src = EmptyImg; // Set fallback image path
    };
    const singleCuisineString = restaurant?.cusines?.join(", ");
    const hanldleNavigate=(path)=>{
        navigate("/" + path + "/menu");
    }
    return (
        <Card className="restaurant-card" key={key} on onClick={()=>hanldleNavigate(restaurant.path)}>
            <div className="image-container">
                <Card.Img variant="top" src={imgDef} alt={restaurant.name} onError={handleError} className="restaurant-image" />
                <FaHeart className="heart-icon" />
            </div>
            <Card.Body className='card-bodyfavourite'>
                <h6 className='restaurant-namerating'> 
                    {restaurant.name} 
                    <span className='ratingstar'>{restaurant?.rating?.rate} <FaStar className='star-iconsrating' /></span>
                </h6>
                <p className="text-res">{singleCuisineString}</p>
                {restaurant?.discount && typeof restaurant.discount === 'object' && Object.keys(restaurant.discount).length > 0 && (
                    <p variant="primary" className="discount-btn">
                        <img src="https://fusionbucket.co.uk/My-account/discountfavout.png" alt="discount-image" className='discount-image' />
                        {restaurant.discount.discount} {restaurant.discount.discountType} off up to {restaurant.discount.minOrder}
                    </p>
                )}
            </Card.Body>
        </Card>
    );
};

const Favouriteorder = ({ getFavouriteList, favourite }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 767;
    const { theme } = useContext(ThemeContext);
    const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
    const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';
    const [restaurantData, setRestaurantData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    
        if (userData && isLoggedIn === true) { // Use strict equality for better practice
            const data = { customer_id: userData.customerId }; // Optional chaining is not needed here
            getFavouriteList(data);
        }
    }, []);

    // Update restaurantData when favourite data updates
    useEffect(() => {
        setRestaurantData(favourite?.message || []);
    }, [favourite]);

    if (location.pathname.includes("/profileV2") && width > breakpoint) {
        navigate("/myaccount");
    }

    return (
        <>
            {width > breakpoint ? (
                <div className='favourite-full'>
                    <Container>
                        <h5 className='favourite-text'>
                            Your Favourite Restaurants
                        </h5>
                        <div className="line-container">
                            <div className="line"></div>
                            <div className="text">ALL RESTAURANTS</div>
                            <div className="line"></div>
                        </div>
                    </Container>

                    <Container>
                        <div className="App">
                            {restaurantData && restaurantData.length > 0 ? (
                                <div className="card-container">
                                    {restaurantData.map((restaurant, index) => (
                                        <RestaurantCard key={index} restaurant={restaurant} favourite={favourite} getFavouriteList={getFavouriteList} />
                                    ))}
                                </div>
                            ) : (
                                <div className="no-favourites">
                                    <img src={IMAGE_URL} alt="No favourites" />
                                    <h5>You Don’t Have Any Favourites!</h5>
                                    <button onClick={() => navigate('/menu')}>Menu</button>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            ) : (
                <div className='favourite-full'>
                    <Container>
                        <h5 className='favourite-text'>
                            <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
                            Your Favourite Restaurants
                        </h5>
                        <div className="line-container">
                            <div className="line"></div>
                            <div className="text">ALL RESTAURANTS</div>
                            <div className="line"></div>
                        </div>
                    </Container>
                    <Container>
                        <div className="App">
                            {restaurantData && restaurantData.length > 0 ? (
                                <div className="card-container">
                                    {restaurantData.map((restaurant, index) => (
                                        <RestaurantCard key={index} restaurant={restaurant} favourite={favourite} getFavouriteList={getFavouriteList} />
                                    ))}
                                </div>
                            ) : (
                                <div className="no-favourites">
                                    <img src={IMAGE_URL} alt="No favourites" />
                                    <h5>You Don’t Have Any Favourites!</h5>
                                    <button onClick={() => navigate('/list')}>Menu</button>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    favourite: state.favourite.favourite
});
const mapDispatchToProps = {
    getFavouriteList
};
export default connect(mapStateToProps, mapDispatchToProps)(Favouriteorder);
