
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import './walletmyacc.scss';
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";


const Fusionwallet = () => {
    const { theme } = useContext(ThemeContext);
    const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
    const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';
    const navigate = useNavigate();

  const handlemyaccountclick = () => {
    navigate('/myaccount');
  };
    return (
        <>
        <div className='wallet-module'>
            <Container  >
                <h5 className='text-walletarrow'>
                    <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icon'onClick={handlemyaccountclick} />
                    Credit wallet
                </h5>
                <div className='kyc-container' >
                    <div className="form-header">
                        <img src="https://fusionbucket.co.uk/My-account/cardholder rename.png" alt="wallet" className="wallet-image" />
                        <h5 variant="h5">Enter KYC Details</h5>
                    </div>
                    <form className="kyc-form">
                        <TextField
                            label="Your Name (as Per PAN)"
                            variant="outlined"
                            fullWidth
                            size='small'
                            className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                        />
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size='small'
                            className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                        />
                        <TextField
                            label="PAN Number"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size='small'
                            className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                        />
                        <TextField
                            label="Date Of Birth"
                            type="date"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            size='small'
                            className={theme === "dark" ? " textinputwallettdark" : "textinputwallet"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={
                                <p className='accept-text'>
                                    I Accept  The
                                    <span className='privacy-policy'>
                                        Privacy Policy
                                    </span>
                                    and Product
                                    <span className='privacy-policy'>  T&C</span>
                                </p>
                            }
                        />

                    </form>
                </div>
                <Button variant="contained" color="primary" fullWidth className="submit-buttonwallet">
                    Submit
                </Button>
            </Container>
            </div>
        </>
    );
}

export default Fusionwallet;
