import React, { useState, useContext,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./rating.scss";
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import { useNavigate,useLocation } from 'react-router-dom';
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";


function Ratingmyaccount() {
  const { theme } = useContext(ThemeContext);
  const breakpoint = 767;
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

 

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const navigateto = useNavigate();
  if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/addressaccount");
  }

  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';

  return (
    <section>
       {width > breakpoint ? (
        <>
    <div className='rating-module'>
    <Container className=" container">
      <Row className="mb-4 section">
        <Col xs={12}  className="mb-3 mb-md-0">
        {/* <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} /> */}
        <h5 className='arrow-text'>
        <img src={theme === "dark" ? Imagetopdark : Imagetop} alt="arrow-icon"  className='arrow-iconrating'  onClick={() => navigate('/myaccount')}/>
        Your Rating
        </h5>
        <div className='center-imagetop'>
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage.png" fluid />
          </div>
          <h3 className="rating-htag">Understanding Your Rating</h3>
          <p className="rating-para">
            To foster mutual respect within the Fusion Community, delivery partners will now rate you on a scale of 1 to 5 after each delivery. Just like you rate them, we request that you think about what can affect your delivery partners' happiness and become a 5-star customer.
          </p>
        </Col>
        </Row>
        <Row>
        <Col xs={6} >
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage2.png" fluid />
          </Col>
          <Col  xs={6}  className='ratingdiv'>
          <h3 className='rating-htag'>Short Waiting Times</h3>
          <p className='rating-para'>
            Make sure that the address entered by you is accurate and prevent drop-off delays.
          </p>
        </Col>
      </Row>
      <Row className="mb-4 section">
      <Col xs={6}  className='ratingdiv'>
          <h3 className='rating-htag'>Courtesy</h3>
          <p className='rating-para'>
           Being polite and kind goes a long way.
          </p>
        </Col>
        <Col xs={6}  className="mb-3 mb-md-0">
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage3.png" fluid />
          </Col>
          </Row>
        <Row>
        <Col xs={6}  >
          <Image src="https://fusionbucket.co.uk/My-account/Screenshot 2024-06-24 at 12.58.32 PM 1.png" fluid />
          </Col>
          <Col xs={6} className='ratingdiv'>
          <h3 className='rating-htag'>Generosity</h3>
          <p className='rating-para'>
            Supporting delivery partners by giving them a generous tip if you can afford it.
          </p>
        </Col>
        </Row>
      
      <Row className="mb-4 section">
        <Col xs={12} className="mb-3 mb-md-0">
          <h3 className='rating-htag'>How Is Your Rating Calculated</h3>
          <p className='rating-para'>
            Your rating will show once you receive ratings on minimum 5 food orders. It is calculated as an average of all your past ratings. A consistently good rating means you create joyful experiences for people more likely to serve you.
          </p>
        
        </Col>
        </Row>
        <div className='button-surface'>
        <Button className="button-custom" size="lg" onClick={() => navigate('/myaccount')} block>Okay</Button>
        </div>
    </Container>
    </div>
    </>
       ):(
        <>
    <div className='rating-module'>
    <Container className=" container">
      <Row className="mb-4 section">
        <Col xs={12}  className="mb-3 mb-md-0">
       <h5 className='arrow-text'> <MdOutlineArrowBackIos className='arrowtop-iconsrating' onClick={() => navigate('/myaccount')}/>Your Rating</h5>
        {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} alt="arrow-icon"  className='arrow-rating' onClick={handlemyaccountclick}/> */}
        <div className='center-imagetop'>
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage.png" fluid />
          </div>
          <h2 className="rating-htag">Understanding Your Rating</h2>
          <p className="rating-para">
            To foster mutual respect within the Fusion Community, delivery partners will now rate you on a scale of 1 to 5 after each delivery. Just like you rate them, we request that you think about what can affect your delivery partners' happiness and become a 5-star customer.
          </p>
        </Col>
        </Row>
        <Row>
        <Col xs={6} >
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage2.png" fluid />
          </Col>
          <Col  xs={6} >
          <h2 className='rating-htag'>Short Waiting Times</h2>
          <p className='rating-para'>
            Make sure that the address entered by you is accurate and prevent drop-off delays.
          </p>
        </Col>
      </Row>
      <Row className="mb-4 section">
      <Col xs={6} >
          <h2 className='rating-htag'>Courtesy</h2>
          <p className='rating-para'>
           Being polite and kind goes a long way.
          </p>
        </Col>
        <Col xs={6}  className="mb-3 mb-md-0">
          <Image src="https://fusionbucket.co.uk/My-account/ratingimage3.png" fluid />
          </Col>
          </Row>
        <Row>
        <Col xs={6}>
          <Image src="https://fusionbucket.co.uk/My-account/Screenshot 2024-06-24 at 12.58.32 PM 1.png" fluid />
          </Col>
          <Col xs={6}>
          <h2 className='rating-htag'>Generosity</h2>
          <p className='rating-para'>
            Supporting delivery partners by giving them a generous tip if you can afford it.
          </p>
        </Col>
        </Row>
      
      <Row className="mb-4 section">
        <Col xs={12} className="mb-3 mb-md-0">
          <h2 className='rating-htag'>How Is Your Rating Calculated</h2>
          <p className='rating-para'>
            Your rating will show once you receive ratings on minimum 5 food orders. It is calculated as an average of all your past ratings.
          </p>
          <p className='rating-para'> A consistently good rating means you create joyful experiences for people more likely to serve you.</p>
        </Col>
        </Row>
        <div className='button-surface'>
        <Button className="button-custom" size="lg" onClick={() => navigate('/myaccount')}block>Okay</Button>
        </div>
    </Container>
    </div>
    </>
       )}
    
    </section>
  );
}

export default Ratingmyaccount;